export enum CoverageType {
  ContinuousCoverage = "Continuous_Coverage",
  ORP = "ORP"
}

export const IsProduction = (host: string): boolean => {
  return host.trim().endsWith("getaddify.com");
};

//Takes an enum and uses Object.values to return an array of options for a select input with {value: enum, label: enum}
export const EnumToOptions = (
  enumObject: any
): { value: string; label: string }[] => {
  if (!enumObject) {
    return [];
  }

  return Object.values(enumObject).map((value) => {
    return { value: value as string, label: value as string };
  });
};
